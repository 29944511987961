export const ADMIN_ROLE = 'ADMIN';
export const MAINTAINER = 'MAINTAINER';
export const USER_ROLE = 'USER';

export type UserRoleType =
   typeof ADMIN_ROLE |
   typeof MAINTAINER |
   typeof USER_ROLE;

export interface UserDto {
   id?: number;
   email: string;
   password: string;
   firstName?: string;
   lastName?: string;
   language?: string;
   role: UserRoleType;
}
