import React, {useState} from 'react';
import {NavDropdown, ToggleButton} from 'react-bootstrap';
import { InvoiceDto } from '../../../models/invoice.dto';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ModalContainer from '../../layout/modal-container';
import '.././invoices-list.scss';
import {
   useUpdateInvoiceMutation,
   useDownloadInvoiceMutation,
   useApproveInvoiceMutation,
} from '../../../store/invoices/invoices.api';
import { useTranslation } from 'react-i18next';
import RejectDialog from './reject-dialog/reject-dialog';
import HistoryDialog from '../history-dialog/history-dialog';
import {UserDto} from '../../../models/user.dto';
import PdfViewer from '../../elements/pdf-viewer/pdf-viewer';
import DeleteDialog from './delete-dialog/delete-dialog';
import {ThreeDots} from 'react-bootstrap-icons';
import './invoices-list-item.scss';
import { ColumnConfig } from '../columns/column-config';
import { IS_ADMIN } from '../../../utils/role';

interface PropsType {
   columns: ColumnConfig[],
   invoice: InvoiceDto;
   handleInvoiceSelection: (invoice: InvoiceDto, checked: boolean) => void;
   selected: InvoiceDto[];
   user?: UserDto,
}

const InvoicesListItem = ({
   columns,
   invoice,
   selected,
   handleInvoiceSelection,
   user,
}: PropsType) => {
   const [showEditDateModal, setShowEditDateModal] = useState(false);
   const [showRejectModal, setShowRejectModal] = useState(false);
   const [showHistoryModal, setShowHistoryModal] =  useState(false);
   const [showDeleteDialog, setShowDeleteDialog] =  useState(false);
   const [showPdfViewer, setShowPdfViewer] =  useState(false);
   const [paymentDate, setPaymentDate] = useState(
      invoice.paymentDate ? new Date(invoice.paymentDate) : new Date(),
   );
   const [updateInvoice] = useUpdateInvoiceMutation();
   const [downloadInvoice] = useDownloadInvoiceMutation();
   const [approveInvoice] = useApproveInvoiceMutation();
   const [translate] = useTranslation(['account', 'common', 'invoice']);

   const handleSaveEditPaymentDate = () => {
      updateInvoice({
         invoiceId: invoice.invoiceId,
         data: { ...invoice, paymentDate: paymentDate.toISOString() },
      });
      setShowEditDateModal(false);
   };

   const isInvoiceSelected = () => {
      return selected.some((selectedInvoice) => selectedInvoice?.invoiceId === invoice?.invoiceId);
   };

   const datePicker = (
      <DatePicker
         className={'w-100'}
         selected={paymentDate}
         dateFormat='dd/MM/yyyy'
         onKeyDown={(e) => e.preventDefault()}
         onChange={(date: Date) => setPaymentDate(date)}
      />
   );

   const onClickDownload = () => downloadInvoice({ invoiceId: invoice.invoiceId });

   const onClickApprove = () => approveInvoice({ invoiceId: invoice.invoiceId });

   const activeRejections =  invoice.rejections.filter(i => i.active);
   const approvedByUser = invoice.approvals.some((a) => a.active && a.email === user?.email);
   const rejectedByUser = activeRejections.some((r) => r.email == user?.email);


   return (
      <>
         <tr>
            <td className={'selection'} style={{ width: 30 }}>
               <ToggleButton
                  style={{ padding: 6 }}
                  id={`toggle-check-${invoice.invoiceId}`}
                  type='checkbox'
                  variant='outline-primary'
                  checked={isInvoiceSelected()}
                  value={invoice.invoiceId}
                  onChange={() => handleInvoiceSelection(invoice, !isInvoiceSelected())}
               ></ToggleButton>
            </td>
            {
               columns.map(c => {
                  if (c.show) {
                     return c.renderCell(invoice, () => setShowPdfViewer(true));
                  }
               })
            }
            <td className={'action'}>
               <NavDropdown title={<ThreeDots/>} className='actions'>
                     <NavDropdown.Item onClick={() => setShowPdfViewer(true)}>
                        {translate('invoice:invoice.viewInvoice')}
                     </NavDropdown.Item>
                     <NavDropdown.Item onClick={onClickDownload}>
                        {translate('invoice:invoice.downloadInvoice')}
                     </NavDropdown.Item>
                     <NavDropdown.Item onClick={() => setShowHistoryModal(true)}>
                        {translate('common:common.viewHistory')}
                     </NavDropdown.Item>
                     {IS_ADMIN() &&
                        <>
                           <NavDropdown.Divider/>
                           <NavDropdown.Item onClick={() => setShowEditDateModal(true)}>
                              {translate('invoice:invoice.editPaymentDate')}
                           </NavDropdown.Item>
                           <NavDropdown.Divider/>
                           <NavDropdown.Item onClick={onClickApprove} disabled={!!activeRejections?.length || approvedByUser}>
                              {translate('invoice:invoice.approve')}
                           </NavDropdown.Item>
                           <NavDropdown.Item onClick={() => setShowRejectModal(true)} disabled={rejectedByUser}>
                              {translate('invoice:invoice.reject')}
                           </NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item onClick={() => setShowDeleteDialog(true)} disabled={!activeRejections?.length}>
                              {translate('common:common.delete')}
                           </NavDropdown.Item>
                        </>
                     }
               </NavDropdown>
            </td>
         </tr>

         <ModalContainer
            modalContent={datePicker}
            modalHeader={translate('invoice:invoice.editPaymentDate')}
            submitButtonText={translate('common:common.save')}
            show={showEditDateModal}
            onCancel={() => setShowEditDateModal(false)}
            onSubmit={handleSaveEditPaymentDate}
         ></ModalContainer>

         <RejectDialog invoiceId={invoice.invoiceId}
                       show={showRejectModal}
                       onHide={() => setShowRejectModal(false)} />

         <DeleteDialog show={showDeleteDialog}
                       onHide={() => setShowDeleteDialog(false)}
                       invoiceId={invoice.invoiceId}
                       invoiceName={invoice.company} />

         {showHistoryModal &&
           <HistoryDialog show={showHistoryModal}
                          onHide={() => setShowHistoryModal(false)}
                          invoice={invoice}
            />
         }
         {showPdfViewer &&
            <PdfViewer invoiceId={invoice.invoiceId} onHide={() => setShowPdfViewer(false)} show={showPdfViewer}/>
         }
      </>
   );
};

export default InvoicesListItem;
