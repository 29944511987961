export const isEmptyArray = (arr: any[]) => !arr || arr.length < 1;
export const notEmptyArray = (arr: any[]) => !isEmptyArray(arr);

export const isEmpty = (value: any) =>
   !value || value.length < 1 || Object.keys(value).length === 0;

export const notEmpty = (value: any) => !isEmpty(value);

export const isBlank = (value: string | undefined | null) => !value || value.trim() === '' ;

export const notBlank = (value: string | undefined | null) => !isBlank(value);