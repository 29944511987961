import React, { useState } from 'react';
import { ADMIN_ROLE, UserDto } from '../../models/user.dto';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import './profile-users.scss';
import AdminDialog from './admin-dialog/admin-dialog';

type ProfileInfoProps = {
   users: UserDto[];
};

const ProfileUsers = ({ users }: ProfileInfoProps) => {
   const [translate] = useTranslation(['account', 'common']);
   const [adminModal, setAdminModal] = useState<{email: string} | undefined>(undefined);

   const columns = ['email', 'user', 'role'];

   const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

   return (
      <>
         <Table variant='light' striped table-borderless='true' hover>
            <thead>
               <tr>
                  {columns.map((c, index) => (
                     <th key={index}>
                        {capitalizeFirstLetter(translate(`account:${c}`))}
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {users.map((user, index) => (
                  <tr key={index}>
                     <td>{user.email}</td>
                     <td>{user.firstName}</td>
                     <td className={'role-column'}>
                        {user.role === ADMIN_ROLE
                           ? ADMIN_ROLE
                           : <Button
                                 className={'justify-content-center button'}
                                 variant="dark"
                                 onClick={() => setAdminModal({ email: user.email })}
                              >
                                 {translate('common:common.convertToAdmin')}
                              </Button>
                        }
                     </td>
                  </tr>
               ))}
            </tbody>
         </Table>
         <AdminDialog
            show={adminModal != undefined}
            onHide={() => setAdminModal(undefined)}
            userEmail={adminModal?.email ?? ''}
         />
      </>
   );
};

export default ProfileUsers;
