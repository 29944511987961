import { useTranslation } from 'react-i18next';

interface PropsType {
   invoiceIds: number[];
}

const ExportWarningModal = ({ invoiceIds }: PropsType) => {
   const [translate] = useTranslation('invoice');

   return (
      <div>
         <div>
            {translate('invoice.invoicesAlreadyPaidInfo')}:
            {invoiceIds.map(id => <div key={id}> {id} </div>)}
         </div>
      </div>
   );
};

export default ExportWarningModal;
