import React from 'react';
import { useTranslation } from 'react-i18next';
import './change-password.scss'

interface PropsType {
   currentPassword: string;
   newPassword: string;
   confirmPassword: string;
   setCurrentPassword: (arg: string) => void;
   setNewPassword: (arg: string) => void;
   setConfirmPassword: (arg: string) => void;
}

const ChangePasswordModal = ({
   currentPassword, newPassword, confirmPassword, setCurrentPassword, setNewPassword, setConfirmPassword
}: PropsType) => {
   const [translate] = useTranslation('common');

   return (
      <div className={'container'}>
         <div className={'row'}>
            <label>{translate('settings.currentPassword')}:</label>
            <input
               type="password"
               value={currentPassword}
               onChange={(e) => setCurrentPassword(e.target.value)}
               required
            />
         </div>
         <div className={'row'}>
            <label>{translate('settings.newPassword')}:</label>
            <input
               type="password"
               value={newPassword}
               onChange={(e) => setNewPassword(e.target.value)}
               required
            />
         </div>
         <div className={'row'}>
            <label>{translate('settings.confirmNewPassword')}:</label>
            <input
               type="password"
               value={confirmPassword}
               onChange={(e) => setConfirmPassword(e.target.value)}
               required
            />
         </div>
      </div>
   );
};

export default ChangePasswordModal;
