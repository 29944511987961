import {Button, Modal} from 'react-bootstrap';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import { useUpdateUserRoleToAdminMutation } from '../../../store/user/user.api';

interface Props {
   show: boolean,
   onHide: () => void,
   userEmail: string
}

const AdminDialog = ({show, onHide, userEmail}: Props) => {
   const [translate] = useTranslation(['common']);
   const [updateUserRoleToAdmin] = useUpdateUserRoleToAdminMutation();

   const onAssignAdmin = () => {
      updateUserRoleToAdmin(userEmail);
      onHide();
   };
   return (
      <Modal show={show} onHide={onHide} dialogClassName='delete-dialog'>
         <Modal.Header>
            <Modal.Title>{translate('common:modal.assignAdminTitle')}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Trans i18nKey="common:modal.assignAdminText"
                   tOptions={{userEmail}}/>
         </Modal.Body>
         <Modal.Footer>
            <Button variant='secondary' onClick={onHide}>
               {translate('common:common.cancel')}
            </Button>
            <Button variant='primary' onClick={onAssignAdmin}>
               {translate('common:common:ok')}
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default AdminDialog;